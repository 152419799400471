import React from 'react'
import './footer.css'
import { Link } from 'react-router-dom'
import logo from './logo3.png';

 const Footer =()=> {
  return (
    <>
      <section className="footer__section">
      <div className="footer__width">
      <div className="footer_row">

        <div className='footer_coll' >
          <img src={logo} alt="logo" />
          <p>We connect companies across the USA with top IT talent, ensuring a seamless and efficient hiring process for both employers and candidates.</p>
          <br />
            
        </div>
        <div className='footer_coll' >
          <a href=""></a>
          <a href=""></a>
          <a></a>s
        </div>
        <div className='footer_coll'  >
        <h2>Get notifications on Email</h2>
        
        <p>The latest notifications news, articles, sent to your inbox weekly.</p>
        
        </div>

 
        </div>
        
        </div>

      </section> 
    </>
  )
}
export default Footer;