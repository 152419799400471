import React, { useEffect } from 'react'
import {motion} from "framer-motion"


import '../components/Styles/home.css'
import '../components/Styles/homesection.css'

import {Helmet}from"react-helmet";
import AOS from 'aos';
import 'aos/dist/aos.css';
import hpic from '../images/hpic1-min.png'
import hpic2 from '../images/hpic2.png'
import ic1 from '../images/ic1.png';
import ic2 from '../images/ic2.png';
import ic3 from '../images/ic3.png';
import ic4 from '../images/ic4.png';
import ic5 from '../images/ic5.png';
import ic6 from '../images/ic6.png';
import ic7 from '../images/ic7.png';
import ic8 from '../images/ic8.png';
import un1 from '../images/Union1.png';
import un2 from '../images/Union2.png';
 const Home =()=> {
  useEffect(()=>{
    AOS.init();
  })
  return (
    <div>
      <Helmet>
      
      <title>Your Gateway to Top IT Talent - Hire the Best IT Professionals Today</title>
      <meta name="description" content="Discover your gateway to top IT talent! Hire the best IT professionals today and boost your business with expert tech solutions"/>
      < meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <meta charset="UTF-8"/>
      
      </Helmet>

      <section  className='header_top'>
      <div className='headertp'  >
      <br /><br />
        <div className='rowwww'>
         <div className='h_col'>
        <h1>Your Gateway to Top IT Talent </h1>
<br />
  <img src={hpic} alt="paint"  />
  <br /><br />
     <h2>Discover and hire the best remote IT professionals effortlessly. Connect with skilled candidates across the USA.</h2>
   <br />


     <motion.button   whileHover={{scale: 1.1,}}  data-aos="zoom-in"><a href="contact">Get Started</a> </motion.button>
         </div>
         <div className='h_col2a' >
             <img src={hpic2} alt="pic" />
         </div> 
        </div>
      </div>
      </section>


     <section className=''>
        <div className='home__div'>
             <h2>Looking for a Resource In?</h2>
             <br /><br />
           <div className='home__row'>
             <motion.div whileHover={{scale: 0.9,}} className='home__col'>
             <img src={ic6} alt="" />
              <h2>IT Support Specialist</h2>
              <p>235 available</p>
  
             </motion.div>
             <motion.div whileHover={{scale: 0.9,}} className='home__col'>
             <img src={ic1} alt="" />
              <h2>Cyber Security</h2>
              <p>756 available</p>
  
             </motion.div>
             <motion.div whileHover={{scale: 0.9,}} className='home__col'>
             <img src={ic2} alt="" />
              <h2>System Analyst</h2>
              <p>140 available</p>
   
             </motion.div>
 
             <motion.div whileHover={{scale: 0.9,}} className='home__col'>
             <img src={ic7} alt="" />
              <h2>Web Developers</h2>
              <p>325 available</p>
  
             </motion.div>
 
           </div>

<br />
<div className='home__row'>
<motion.div whileHover={{scale: 0.9,}} className='home__col'>

             <img src={ic3} alt="" />
              <h2>App<br /> Developer</h2>
              <p>436 available</p>
    
             </motion.div>
             <motion.div whileHover={{scale: 0.9,}} className='home__col'>
             <img src={ic4} alt="" />
              <h2>Network &<br /> Security</h2>
              <p>542 available</p>
  
             </motion.div>
             <motion.div whileHover={{scale: 0.9,}} className='home__col'>

             <img src={ic5} alt="" />
              <h2>Data <br /> Analyst</h2>
              <p>211 available</p>
  
   
             </motion.div>
 
             <motion.div whileHover={{scale: 0.9,}} className='home__col'>
             <img src={ic8} alt="" />
              <h2>Human <br /> Resource</h2>
              <p>346 available</p>
  
             </motion.div>
 
           </div>

        </div>
     </section>
     <br />
     <section className=''>
       <div className='h_section4'>
       <h2>How it Works?</h2>
      <br /><br />
 
            <div className='mh_row'>
                  <div className='mh_col'>
                  Apply
                  </div>
                  <div className='mh_col2'>
                 <img src={un1} style={{width:"32%"}} alt="" />
                 <img src={un2} style={{width:"32%"}} alt="" />
                  </div>
                  <div className='mh_col3'>
                  Browse and apply for jobs that match your skills and interests.
                  </div>
            </div>
            <br />
            <div className='mh_row'>
                  <div className='mh_col'>
                  Screen
                  </div>
                  <div className='mh_col2'>
                  <img src={un1} style={{width:"32%"}} alt="" />
                 <img src={un2} style={{width:"32%"}} alt="" />
                  </div>
                  <div className='mh_col3'>
                  We review your application and match you with suitable job opportunities.                  </div>
            </div>
            <br />
            <div className='mh_row'>
                  <div className='mh_col'>
                  Interview
                  </div>
                  <div className='mh_col2'>
                  <img src={un1} style={{width:"32%"}} alt="" />
                 <img src={un2} style={{width:"32%"}} alt="" />
                  </div>
                  <div className='mh_col3'>
                  Participate in interviews with potential employers.
                  </div>
            </div>
            <br />
            <div className='mh_row'>
                  <div className='mh_col'>
                  Call to Action
                  </div>
                  <div className='mh_col2'>
                  <img src={un1} style={{width:"32%"}} alt="" />
                 <img src={un2} style={{width:"32%"}} alt="" />
                  </div>
                  <div className='mh_col3'>
                  Ready to Find Your Dream Job?
                                    </div>
            </div>
       </div>
     </section>
     <section className=''>
    <div className='h_section3'>
      <h2>How it Works?</h2>
      <br /><br />
      <motion.div whileHover={{scale: 0.9,}} className='bl1'><h3>Apply</h3></motion.div>
      <motion.div whileHover={{scale: 0.9,}} className='bl2'><h3>Browse and apply for jobs that match your skills and interests. </h3></motion.div>
    <br /><br />
    <motion.div whileHover={{scale: 0.9,}} className='bl1'><h3>Screen</h3></motion.div>
    <motion.div whileHover={{scale: 0.9,}} className='bl2'><h3>We review your application and match you with suitable job opportunities. </h3></motion.div>
    <br /><br />
    <motion.div whileHover={{scale: 0.9,}} className='bl1'><h3>Interview </h3></motion.div>
    <motion.div whileHover={{scale: 0.9,}} className='bl2'><h3>Participate in interviews with potential employers.</h3></motion.div>
    <br /><br />
    <motion.div whileHover={{scale: 0.9,}} className='bl1'><h3>Get Hired </h3></motion.div>
    <motion.div whileHover={{scale: 0.9,}} className='bl2'><h3>Secure your ideal remote IT job and start your new career. </h3></motion.div>
    <br /><br />
    <motion.div whileHover={{scale: 0.9,}} className='bl1'><h3>Call to Action </h3></motion.div>
    <motion.div whileHover={{scale: 0.9,}} className='bl2'><h3>Ready to Find Your Dream Job?  </h3></motion.div>
    </div>
   </section>
<br /><br />
<br /><br />

 <section className='sec__last_1'>
  <div className='sec__last1m'>
    <br /><br />
  <p>Join Hiring Gate today and take the next step <br />in your IT career. </p>
  <motion.button   whileHover={{scale: 1.1,}}  data-aos="zoom-in"><a href="contact">Join Now</a></motion.button>
  </div>
 </section>
<br /><br />
 <section className=''>
  <div className='lst_sec'>
   <h2>What Our Clients Say…</h2>
   <br /><br />
   <div className='lst_row'>
    
    <motion.div whileHover={{scale: 0.9,}}  className='lst_col'>
     <div className='ro_m'>
      <div className='ro_col1'><img src="" alt="" /></div>
      <div className='ro_col2'>
        <h3>Cali Huffman</h3>
        <p>Student on UI / UX designer course</p>
      </div>
     </div>
     <p>Nisi dui ut imperdiet convallis orci. Id viverra urna cras quis amet, accumsan, iaculis donec nunc. In gravida purus felis ullamcorper vitae enim sed adipiscing pellentesque. </p>
    </motion.div>

    <motion.div whileHover={{scale: 0.9,}} className='lst_col'>
     <div className='ro_m'>
      <div className='ro_col1'><img src="" alt="" /></div>
      <div className='ro_col2'>
        <h3>Cali Huffman</h3>
        <p>Student on UI / UX designer course</p>
      </div>
     </div>
     <p>Nisi dui ut imperdiet convallis orci. Id viverra urna cras quis amet, accumsan, iaculis donec nunc. In gravida purus felis ullamcorper vitae enim sed adipiscing pellentesque. </p>
    </motion.div>

    <motion.div whileHover={{scale: 0.9,}}  className='lst_col'>
     <div className='ro_m'>
      <div className='ro_col1'><img src="" alt="" /></div>
      <div className='ro_col2'>
        <h3>Cali Huffman</h3>
        <p>Student on UI / UX designer course</p>
      </div>
     </div>
     <p>Nisi dui ut imperdiet convallis orci. Id viverra urna cras quis amet, accumsan, iaculis donec nunc. In gravida purus felis ullamcorper vitae enim sed adipiscing pellentesque. </p>
    </motion.div>
   </div>


  </div>
 </section>
<br />



    </div>
  )
}
export default Home;